<template>
  <div id="pickList">
    <el-dialog
      :title="pickListFormTitle"
      width="680px"
      :visible.sync="pickListDialogVisible"
      :close-on-click-modal="false"
      @close="pickListDialogClose"
    >
      <el-form
        ref="pickListFormRef"
        :model="pickListForm"
        :rules="pickListFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="物料名称" prop="materialName">
              <el-input v-model="pickListForm.materialName" placeholder="请输入物料名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格/装量" prop="spec">
              <el-input v-model="pickListForm.spec" placeholder="请输入规格/装量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物料编号" prop="materialNo">
              <el-input v-model="pickListForm.materialNo" placeholder="请输入物料编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位" prop="unit">
              <el-input v-model="pickListForm.unit" placeholder="请输入单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领料量" prop="requisitionAmount">
              <el-input v-model="pickListForm.requisitionAmount" placeholder="请输入领料量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实发量" prop="actualAmount">
              <el-input v-model="pickListForm.actualAmount" placeholder="请输入实发量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用于产品批号" prop="productBatchNo">
              <el-input v-model="pickListForm.productBatchNo" placeholder="请输入用于产品批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验单号" prop="inspectionOrderNo">
              <el-input v-model="pickListForm.inspectionOrderNo" placeholder="请输入检验单号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="pickListForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="pickListDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="pickListFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="物料名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入物料名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="pickListPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialName" label="物料名称" />
      <el-table-column prop="spec" label="规格/装量" />
      <el-table-column prop="materialNo" label="物料编号" />
      <el-table-column prop="unit" label="单位" />
      <el-table-column prop="requisitionAmount" label="领料量" />
      <el-table-column prop="actualAmount" label="实发量" />
      <el-table-column prop="productBatchNo" label="用于产品批号" />
      <el-table-column prop="inspectionOrderNo" label="检验单号" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="pickListPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addPickList, deletePickList, updatePickList, selectPickListInfo, selectPickListList } from '@/api/storage/pickList'

export default {
  data () {
    return {
      pickListDialogVisible: false,
      pickListFormTitle: '',
      pickListForm: {
        id: '',
        materialName: '',
        spec: '',
        materialNo: '',
        unit: '',
        requisitionAmount: '',
        actualAmount: '',
        productBatchNo: '',
        inspectionOrderNo: '',
        remarks: ''
      },
      pickListFormRules: {
        materialName: [{ required: true, message: '物料名称不能为空', trigger: ['blur', 'change']}]
      },
      pickListPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: ''
      }
    }
  },
  created () {
    selectPickListList(this.searchForm).then(res => {
      this.pickListPage = res
    })
  },
  methods: {
    pickListDialogClose () {
      this.$refs.pickListFormRef.resetFields()
    },
    pickListFormSubmit () {
      if (this.pickListFormTitle === '领料单详情') {
        this.pickListDialogVisible = false
        return
      }
      this.$refs.pickListFormRef.validate(async valid => {
        if (valid) {
          if (this.pickListFormTitle === '新增领料单') {
            await addPickList(this.pickListForm)
          } else if (this.pickListFormTitle === '修改领料单') {
            await updatePickList(this.pickListForm)
          }
          this.pickListPage = await selectPickListList(this.searchForm)
          this.pickListDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.pickListFormTitle = '新增领料单'
      this.pickListDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deletePickList(row.id)
        if (this.pickListPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.pickListPage = await selectPickListList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.pickListFormTitle = '修改领料单'
      this.pickListDialogVisible = true
      this.selectPickListInfoById(row.id)
    },
    handleInfo (index, row) {
      this.pickListFormTitle = '领料单详情'
      this.pickListDialogVisible = true
      this.selectPickListInfoById(row.id)
    },
    selectPickListInfoById (id) {
      selectPickListInfo(id).then(res => {
        this.pickListForm.id = res.id
        this.pickListForm.materialName = res.materialName
        this.pickListForm.spec = res.spec
        this.pickListForm.materialNo = res.materialNo
        this.pickListForm.unit = res.unit
        this.pickListForm.requisitionAmount = res.requisitionAmount
        this.pickListForm.actualAmount = res.actualAmount
        this.pickListForm.productBatchNo = res.productBatchNo
        this.pickListForm.inspectionOrderNo = res.inspectionOrderNo
        this.pickListForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectPickListList(this.searchForm).then(res => {
        this.pickListPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectPickListList(this.searchForm).then(res => {
        this.pickListPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectPickListList(this.searchForm).then(res => {
        this.pickListPage = res
      })
    }
  }
}
</script>

<style>
</style>
